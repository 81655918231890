<template>
  <div v-if="storageSystem" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <PageHeader>
      <template v-if="storageSystem.number">
        {{ $t('storageSystem') }} -
        <span class="fw-semi-bold">{{ $t('edit') }}</span>
      </template>
      <template v-else>
        {{ $t('storageSystem') }} -
        <span class="fw-semi-bold">{{ $t('create') }}</span>
      </template>
    </PageHeader>

    <b-card-group deck>
      <b-card title="Leistungsdaten" class="mb-4 storage-system-card">
        <b-card-text class="card-grid-container">
          <div>{{ $t('capacity_kwh') }}</div>
          <div>
            <strong>{{ $n(storageSystemCapacity, 'decimal_single') }}</strong>
          </div>
        </b-card-text>
      </b-card>
    </b-card-group>

    <Widget v-if="storageSystem">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Herstellerinformationen</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('manufacturer')"
            label-for="manufacturer"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="storageSystem.manufacturer"
              type="text"
              :placeholder="$t('manufacturer')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('type')"
            label-for="type"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="storageSystem.type" type="text" :placeholder="$t('type')" />
          </b-form-group>

          <legend>
            <strong>Dokumente</strong>
          </legend>

          <DropzoneUpload
            v-if="!isNew"
            :complete="isAttachmentUploadComplete"
            :parentId="storageSystem.id"
            :uploadCallback="uploadStorageSystemAttachment"
            v-on:dropzone-upload:reset="isAttachmentUploadComplete = false"
          />

          <AttachmentList
            :attachments="storageSystem.attachments"
            v-on:attachment-list:delete="onDeleteStorageSystemAttachment"
          ></AttachmentList>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>{{ $t('inverter') }}</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('inverter')"
            label-for="inverter"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('inverter')"
              v-model="storageSystem.inverter"
              id="inverter"
              :options="filteredInverters"
              :allow-empty="true"
              :show-labels="false"
              track-by="number"
              v-on:search-change="onInverterSearch"
              :internal-search="false"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.manufacturer }} - {{ option.type }}
              </template>
              <template slot="option" slot-scope="{ option }">
                {{ option.manufacturer }} - {{ option.type }}
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('inverterCount')"
            label-for="inverterCount"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="storageSystem.inverterCount"
              type="text"
              :placeholder="$t('inverterCount')"
            />
          </b-form-group>
          <legend>
            <strong>{{ $t('battery') }}</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('battery')"
            label-for="battery"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('battery')"
              v-model="storageSystem.battery"
              id="battery"
              :options="filteredBatteries"
              :allow-empty="true"
              :show-labels="false"
              track-by="number"
              v-on:search-change="onBatterySearch"
              :internal-search="false"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.manufacturer }} - {{ option.type }}
              </template>
              <template slot="option" slot-scope="{ option }">
                {{ option.manufacturer }} - {{ option.type }}
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('batteryCount')"
            label-for="batteryCount"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="storageSystem.batteryCount"
              type="text"
              :placeholder="$t('batteryCount')"
            />
          </b-form-group>
          <legend>
            <strong>{{ $t('energyFlowDirectionSensor') }}</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('energyFlowDirectionSensor')"
            label-for="energyFlowDirectionSensor"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('energyFlowDirectionSensor')"
              v-model="storageSystem.energyFlowDirectionSensor"
              id="energyFlowDirectionSensor"
              :options="filteredEnergyFlowFirectionSensors"
              :allow-empty="true"
              :show-labels="false"
              track-by="number"
              v-on:search-change="onEnergyFlowDirectionSensorSearch"
              :internal-search="false"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.manufacturer }} - {{ option.type }}
              </template>
              <template slot="option" slot-scope="{ option }">
                {{ option.manufacturer }} - {{ option.type }}
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('energyFlowDirectionSensorCount')"
            label-for="energyFlowDirectionSensorCount"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="storageSystem.energyFlowDirectionSensorCount"
              type="text"
              :placeholder="$t('energyFlowDirectionSensorCount')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <b-form-group class="form-action">
            <b-row>
              <b-col class="text-right">
                <ConfirmDeleteButton
                  v-if="!isNew"
                  v-bind:disabled="buttonsDisabled"
                  v-bind:id="storageSystem.number"
                  v-on:confirm-delete-button:confirmed="remove($event)"
                />

                <router-link :to="{ name: 'StorageSystemsPage' }">
                  <b-button :disabled="buttonsDisabled" class="mr-xs" variant="default">{{
                    $t('cancel')
                  }}</b-button>
                </router-link>

                <b-button
                  :disabled="buttonsDisabled"
                  @click.prevent="saveStorageSystem"
                  variant="bg"
                  type="submit"
                  class="mr-xs"
                  >{{ $t('save') }}</b-button
                >
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import ConfirmDeleteButton from '@/components/ConfirmDeleteButton';
import { mapGetters, mapActions } from 'vuex';
import { yesNo } from '@/helpers/enums';
import Multiselect from 'vue-multiselect';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentList from '@/components/AttachmentList';
import PageHeader from '@/components/PageHeader';

export default {
  name: 'StorageSystemEditPage',
  components: {
    Widget,
    Breadcrumbs,
    ConfirmDeleteButton,
    Multiselect,
    DropzoneUpload,
    AttachmentList,
    PageHeader,
  },
  props: {
    storageSystemNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      user: {},
      buttonsDisabled: false,
      isAttachmentUploadComplete: false,
      filteredInverters: [],
      filteredBatteries: [],
      filteredEnergyFlowFirectionSensors: [],
    };
  },
  computed: {
    ...mapGetters([
      'getStorageSystem',
      'getStorageSystemByNumber',
      'getInverters',
      'getBatteries',
      'getEnergyFlowDirectionSensors',
    ]),
    optionsYesNo() {
      return yesNo();
    },
    storageSystem() {
      return this.getStorageSystemByNumber(this.number);
    },
    isNew() {
      return this.storageSystem.id === '_new';
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        {
          name: this.$t('storageSystems'),
          route: { name: 'StorageSystemsPage' },
        },
        {
          name: this.storageSystem.number ? 'bearbeiten' : 'erstellen',
        },
      ];
    },
    storageSystemCapacity() {
      if (this.storageSystem && this.storageSystem.battery) {
        return this.storageSystem.batteryCount * this.storageSystem.battery.capacity;
      }
      return 'N/A';
    },
  },
  methods: {
    ...mapActions([
      'initStorageSystem',
      'fetchStorageSystems',
      'fetchStorageSystemByNumber',
      'updateStorageSystem',
      'createStorageSystem',
      'deleteStorageSystem',
      'addEmptyStorageSystemContact',
      'removeStorageSystemContact',
      'fetchInverters',
      'fetchBatteries',
      'fetchEnergyFlowDirectionSensors',
      'deleteStorageSystemAttachment',
      'uploadStorageSystemAttachment',
    ]),
    async saveStorageSystem() {
      try {
        if (this.storageSystem.number && this.storageSystem.number != '_new') {
          await this.updateStorageSystem(this.storageSystem);
        } else {
          await this.createStorageSystem(this.storageSystem);
        }
        this.$router.push({ name: 'StorageSystemsPage' });
      } catch (e) {
        // console.log(e);
      }
    },
    async remove(storageSystemNumber) {
      if (storageSystemNumber === this.storageSystem.number) {
        this.deleteStorageSystem(this.storageSystem.number);
        await this.fetchStorageSystems();
        this.$router.push({ name: 'StorageSystemsPage' });
      }
    },
    onAddContact() {
      this.addEmptyStorageSystemContact(this.storageSystem);
    },
    onTrashContact(index) {
      this.removeStorageSystemContact({
        updatedStorageSystem: this.storageSystem,
        index: index,
      });
    },
    isDisabled(e) {
      e.preventDefault();
    },
    onDeleteStorageSystemAttachment(event) {
      this.deleteStorageSystemAttachment({
        storageSystemId: this.storageSystem.id,
        attachmentId: event.attachmentId,
      });
    },
    onInverterSearch(query) {
      this.filteredInverters = [];
      this.getInverters.map((inverter) => {
        if (
          inverter.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          inverter.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredInverters.push(inverter);
        }
      });
    },
    onBatterySearch(query) {
      this.filteredBatteries = [];
      this.getBatteries.map((battery) => {
        if (
          battery.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          battery.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredBatteries.push(battery);
        }
      });
    },
    onEnergyFlowDirectionSensorSearch(query) {
      this.filteredEnergyFlowFirectionSensors = [];
      this.getEnergyFlowDirectionSensors.map((energyFlowDirectionSensor) => {
        if (
          energyFlowDirectionSensor.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          energyFlowDirectionSensor.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredEnergyFlowFirectionSensors.push(energyFlowDirectionSensor);
        }
      });
    },
  },
  async mounted() {
    await this.fetchInverters();
    await this.fetchBatteries();
    await this.fetchEnergyFlowDirectionSensors();
    this.filteredInverters = this.getInverters;
    this.filteredBatteries = this.getBatteries;
    this.filteredEnergyFlowFirectionSensors = this.getEnergyFlowDirectionSensors;

    if (!this.storageSystem && this.storageSystemNumber) {
      this.number = this.storageSystemNumber;
      this.fetchStorageSystemByNumber(this.number);
    } else {
      this.initStorageSystem();
      this.number = '_new';
    }

    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadStorageSystemAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style scoped lang="scss">
.storageSystemContacts {
  text-align: right;
}
.trash-contact {
  cursor: pointer;
}
.storage-system-card {
  max-width: 25rem;
}
.card-grid-container {
  display: grid;
  grid-template-columns: 5fr 1fr;
  column-gap: 1em;
  row-gap: 0.8em;
}
</style>
